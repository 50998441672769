import { create } from "zustand";
import { persist } from "zustand/middleware";

export interface ProductInterface {
  id: number;
  name: string;
  slug: string;
  price: number;
  usd_price?: number;
  discounted_price?: number;
  discounted_usd_price?: number;
  thumbnail_url: string;
  stock: number;
  sku: string;
  type: "single" | "variant" | "subscription" | "virtual";
  category_id: number;
  grams: number;
  variant_id?: number;
}

export interface VariantInfo {
  id: number;
  name: string;
  value: string;
}

interface CartProduct extends ProductInterface {
  qty: number;
  variant?: VariantInfo;
}

interface Coupon {
  id: number;
  code: string;
  discount_type: string;
  discount_amount: number;
  discount_amount_usd: number;
  applicable_products: number[];
}

interface ShippingMethod {
  id: number;
  code: string;
  title: string;
  description: string;
  price: number;
  is_selected: boolean;
}

interface CartState {
  cart: CartProduct[];
  coupon: Coupon | null;
  shippingMethods: ShippingMethod[];
  addToCart: (
    product: ProductInterface,
    quantity: number,
    variant?: VariantInfo
  ) => void;
  removeFromCart: (productId: number, variantId?: number) => void;
  increaseQuantity: (productId: number, variantId?: number) => void;
  decreaseQuantity: (productId: number, variantId?: number) => void;
  clearCart: () => void;
  addCoupon: (coupon: Coupon) => void;
  removeCoupon: () => void;
  setShippingMethods: (methods: ShippingMethod[]) => void;
  selectShippingMethod: (methodId: number) => void;
  clearShippingMethods: () => void;
}

const useCartStore = create<CartState>()(
  persist(
    (set) => ({
      cart: [],
      coupon: null,
      shippingMethods: [],
      addToCart: (product, quantity, variant) =>
        set((state) => {
          const existingProductIndex = state.cart.findIndex(
            (item) =>
              item.id === product.id &&
              (product.type !== "variant" || item.variant?.id === variant?.id)
          );

          if (existingProductIndex > -1) {
            const updatedCart = [...state.cart];
            updatedCart[existingProductIndex].qty += quantity;
            return { cart: updatedCart };
          } else {
            const newProduct: CartProduct = {
              ...product,
              qty: quantity,
              variant: variant,
            };
            return { cart: [...state.cart, newProduct] };
          }
        }),
      removeFromCart: (productId, variantId) =>
        set((state) => ({
          cart: state.cart.filter(
            (item) =>
              !(
                item.id === productId &&
                (item.type !== "variant" || item.variant?.id === variantId)
              )
          ),
        })),
      increaseQuantity: (productId, variantId) =>
        set((state) => ({
          cart: state.cart.map((item) =>
            item.id === productId &&
            (item.type !== "variant" || item.variant?.id === variantId)
              ? { ...item, qty: item.qty + 1 }
              : item
          ),
        })),
      decreaseQuantity: (productId, variantId) =>
        set((state) => ({
          cart: state.cart
            .map((item) =>
              item.id === productId &&
              (item.type !== "variant" || item.variant?.id === variantId)
                ? { ...item, qty: item.qty - 1 }
                : item
            )
            .filter((item) => item.qty > 0),
        })),
      clearCart: () => set({ cart: [], coupon: null, shippingMethods: [] }),
      addCoupon: (coupon) => set({ coupon }),
      removeCoupon: () => set({ coupon: null }),
      setShippingMethods: (methods) => set({ shippingMethods: methods }),
      selectShippingMethod: (methodId) =>
        set((state) => ({
          shippingMethods: state.shippingMethods.map((method) => ({
            ...method,
            is_selected: method.id === methodId,
          })),
        })),
      clearShippingMethods: () => set({ shippingMethods: [] }),
    }),
    {
      name: "persist:cart",
    }
  )
);

export default useCartStore;
